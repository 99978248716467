<template>
  <v-container>
    <v-card>
      <v-card-title>
        Reset Your Password
      </v-card-title>
      <v-card-text v-if="tokenValid">
        <div>
          <label>Email: {{ userEmail }}</label>
          <v-text-field label="New Password"
                        v-model="newPassword"
                        type="password"
                        :rules="passwordRules"
                        required></v-text-field>
          <v-text-field label="Confirm New Password"
                        v-model="confirmPassword"
                        type="password"
                        :rules="confirmPasswordRules"
                        required></v-text-field>
          <v-btn @click="submitNewPassword" :disabled="!isFormValid">
            Submit
          </v-btn>
        </div>
        <div v-if="submissionMessage">
          {{ submissionMessage }}
        </div>
      </v-card-text>
      <v-card-text v-else>
        <p>{{ errorMessage }}</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>export default {
  data() {
    return {
      userEmail: '',
      newPassword: '',
      confirmPassword: '',
      tokenValid: false,
      errorMessage: '',
      submissionMessage: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 8 || 'Password must be at least 8 characters long'
      ],
      confirmPasswordRules: [
        v => v === this.newPassword || 'Passwords must match'
      ]
    };
  },
  created() {
    this.validateToken();
  },
  methods: {
    async validateToken() {
      console.log("receive token", this.$route.params.token);
      console.log("URL: ", `/api/users/validate-reset-token?token=${this.$route.params.token}`);
      try {
        const response = await fetch(`https://${this.$serverAddress}/api/users/validate-reset-token?token=${this.$route.params.token}`);
        if (response.ok) {
          const data = await response.json();
          this.userEmail = data.data.email;
          this.tokenValid = true;
        } else {
          this.routeToNotFound();
        }
      } catch (error) {
        console.error('Failed to validate token due to server error.');
        this.routeToNotFound();
      }
    },

    routeToNotFound() {
      this.$router.push({ name: 'error' });
    },

    async submitNewPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.submissionMessage = "Passwords do not match.";
        return;
      }
      try {
        const response = await fetch(`https://${this.$serverAddress}/api/users/reset-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: this.$route.params.token,
            newPassword: this.newPassword
          })
        });
        if (response.ok) {
          this.submissionMessage = 'Password has been reset successfully.';
          setTimeout(() => {
            this.$router.push('/');
          }, 3000); 
        } else {
          const errorData = await response.json();
          this.submissionMessage = errorData.message || 'Failed to reset password.';
        }
      } catch (error) {
        this.submissionMessage = 'Error occurred while resetting password.';
      }
    }
  },
  computed: {
    isFormValid() {
      return this.newPassword && this.confirmPassword && this.newPassword === this.confirmPassword;
    }
  }
};</script>
